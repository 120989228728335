import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import classnames from "classnames";

//Context
import { AppContext } from "@vahak/core/dist/app-context";

//Styles
import Styles from "./MandateServiceType.module.scss";

//Components
import ModalOrBottomSheetWrapper from "@vahak/core-ui/dist/components/ModalOrBottomSheetWrapper";
import Typography from "@vahak/core-ui/dist/components/Typography";

//Constants
import { ServiceTypeOptions } from "../../../constants/serviceTypeOption";

//Icons
import IconWrapper from "@vahak/core-ui/dist/components/IconWrapper";

//Services
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";

//Custom types
import { MarketType } from "@vahak/core/dist/custom-types/market-type";
import { sleep } from "@vahak/core/dist/methods/sleep";
import AppImg from "../../utils/AppImg";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { MARKET_TYPE } from "@vahak/core/dist/constants/market";

interface MandateServiceTypeProps {
    isLoggedIn?: boolean;
    initialServiceType?: string | null;
    handleServiceTypeChange?: (val: MarketType) => void;
    source: "marketplace" | "transport_directory";
}
const MandateServiceType = ({
    isLoggedIn,
    initialServiceType,
    handleServiceTypeChange,
    source
}: MandateServiceTypeProps) => {
    const { isPhoneVerified, companyId, serviceType, setServiceType, csDashboardUserRoles } = useContext(AppContext);
    const router = useRouter();

    const routerHash = router.asPath.split("#").slice(1)[0];
    const [isMandateServiceTypeOpen, setIsMandateServiceTypeOpen] = useState(false);
    const [selectedServiceType, setSelectedServiceType] = useState(serviceType);

    const ChangeServiceType = routerHash === "changeServiceType";
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    useEffect(() => {
        if (serviceType) setSelectedServiceType(serviceType);
        //Base check - force open service type selection on user request
        if (csDashboardUserRoles) {
            return;
        }
        if (ChangeServiceType) {
            setIsMandateServiceTypeOpen(true);
            return;
        }
        if ((isPhoneVerified && companyId && isLoggedIn) || (serviceType && initialServiceType) || serviceType) {
            setIsMandateServiceTypeOpen(false);
        } else {
            setIsMandateServiceTypeOpen(true);
        }
    }, [router, isLoggedIn, isPhoneVerified, companyId, serviceType, initialServiceType]);

    const trackEventOnServiceTypeChange = (selection: string, isLoadMkt: boolean) => {
        sendGAandMoEngageEvent({
            name: GA4EventNames.MARKET_PLACE["preference_selected"],
            data: {
                source_screen: isLoadMkt ? "load_market" : "lorry_market",
                selection: selection
            }
        });
    };

    const handleClickServiceType = async (value: string) => {
        const mktQuery = router.query["params"]?.[0];
        const isLoadMkt = mktQuery === MARKET_TYPE.LOAD;

        setSelectedServiceType?.(value);
        await sleep(150);
        setServiceType?.(value);
        setIsMandateServiceTypeOpen(false);
        await router.push({ ...router, hash: undefined });
        switch (value) {
            case CompanyServiceType.SHIPPER:
                handleServiceTypeChange?.("lorry");
                trackEventOnServiceTypeChange("find_lorries", isLoadMkt);
                break;
            case CompanyServiceType.FLEET_OWNER:
                handleServiceTypeChange?.("load");
                trackEventOnServiceTypeChange("find_loads", isLoadMkt);
                break;

            default:
                trackEventOnServiceTypeChange("find_loads_lorries", isLoadMkt);
                handleServiceTypeChange?.("load");
                break;
        }
    };
    return (
        <div>
            <ModalOrBottomSheetWrapper
                isOpen={isMandateServiceTypeOpen}
                showCloseIcon={false}
                onToggle={() => {}}
                renderTitle
                modalSize="fit"
                blurBackground
                disableSheetOverlayClick
                disableSheetSwipe
            >
                <div className={Styles.ServiceTypeModalBodyWrapper}>
                    <div className={Styles.headingWrapper}>
                        <Typography size="l" as="p" weight="bold">
                            What are you looking for?
                        </Typography>
                        <Typography size="m" as="p">
                            Select below option accordingly
                        </Typography>
                    </div>
                    <div className={Styles.serviceTypeWrapper}>
                        {ServiceTypeOptions.map(
                            ({ lookingForLabel, lookingForIcon, value, lookingForAlternative }, index) => {
                                return (
                                    <div
                                        id={`select-service-type-${index}`}
                                        className={classnames(
                                            Styles.serviceTypeCard,
                                            selectedServiceType === value ? Styles.active : ""
                                        )}
                                        key={index}
                                        onClick={() => handleClickServiceType(value)}
                                    >
                                        <AppImg src={lookingForIcon} width="65px" height="65px"></AppImg>
                                        <Typography>
                                            {source === "transport_directory" ? lookingForAlternative : lookingForLabel}
                                        </Typography>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </ModalOrBottomSheetWrapper>
        </div>
    );
};

export default MandateServiceType;
